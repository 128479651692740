<template>
	<el-container>
		<y-header></y-header>
		<el-main>
			<div class="banner-container">
				<img :src="banner.typeImg" v-show="banner.typeImg">
				<div class="banner-text">
					<p class="banner-title" v-if="banner.title">{{banner.title}}</p>
					<p class="banner-sec-title" v-if="banner.shortTitle">{{banner.shortTitle}}</p>
				</div>
			</div>
			<div class="page-container fc-c register-cont">
				<div class="form-header">
					<p class="form-header-title">免费注册</p>
					<router-link class="register-tips" :to="{ name: 'login' }">我已登记，直接登录>></router-link>
					<p class="form-header-desc" v-if="banner.description" >{{banner.description}}</p>
				</div>
				<el-form ref="data" :rules="rules" :model="data" label-width="8rem" class="form-container mobile-top">

					<!-- <el-form-item label="客户类型" prop="businessType">
						<el-radio-group v-model="data.businessType">
							<el-radio class="mg-r66" label="01">企业</el-radio>
							<el-radio label="02">个人</el-radio>
						</el-radio-group>
					</el-form-item> -->
					<el-form-item label="企业名称" prop="enterprisePerson">
						<el-input v-model="data.enterprisePerson" maxlength="50" placeholder="请输入企业名称"></el-input>
					</el-form-item>
					<el-form-item label="所在地区" prop="householdAddress">
						<city-select
							type="province"
							extraClass="city-select"
							:value="data.householdAddress"
							placeholder="请选择省市"
							@selected="selectCity"
							class="mg-r10"
						></city-select>
					</el-form-item>
					<el-form-item label="品牌名称">
						<el-input v-model="data.brand" maxlength="50" placeholder="请输入品牌名称"></el-input>
					</el-form-item>
					<el-form-item label="房源数量">
						<div class="dis-f w-p100">
							<el-input v-model="data.houseNum" maxlength="20" placeholder="请输入房源数量" oninput="value=value.replace(/[^0-9.]/g,'')" class="f-1 mg-r10"></el-input>
							<span>间</span>
						</div>
					</el-form-item>
					<el-form-item label="联系人" prop="linkPerson">
						<el-input v-model="data.linkPerson" maxlength="20" placeholder="请输入联系人"></el-input>
					</el-form-item>
					<verify ref="ver" :config="verifyConfig" labelWidth="8rem" @change="syncData"></verify>
					<el-form-item label="补充说明">
						<el-input type="textarea" :rows="5" v-model="data.remark" placeholder="请输入补充说明" maxlength="500" show-word-limit></el-input>
					</el-form-item>
					<div class="form-foot-buttons">
						<!-- <el-button type="cancel" class="mobile-btn" @click="reset">重置</el-button> -->
						<el-button type="primary" class="mobile-btn" @click="onSubmit">提交登记</el-button>
					</div>
				</el-form>
			</div>
		</el-main>
		<y-footer></y-footer>
	</el-container>
</template>
<script>
import { mapState } from "vuex"
import CitySelect from "@/components/CitySelect"
import Verify from "@/components/Verify"
export default {
	data() {
		return {
			banner: {
				typeImg: null,
				title: null,
				shortTitle: null,
				description: null
			},
			data: {
				businessType: "01",
				enterprisePerson: "",
				householdAddress: "",
				brand: "",
				houseNum: "",
				linkPerson: "",
				code: "",
				mobile: "",
				authCode: "",
				remark: ""
			},
			rules: {
				// businessType: [{ required: true, message: "请选择客户类型", trigger: "change" }],
				enterprisePerson: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
				householdAddress: [{ required: true, message: "请选择地区", trigger: "change" }],
				linkPerson: [{ required: true, message: "请输入联系人", trigger: "blur" }],
				code: [{ required: true, message: "请输入图形验证码", trigger: "blur" }],
				mobile: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
				authCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }]
			},
			randomImgUrl: null,
			verifyConfig: {
				mobile: { label: "手机号码", prop: "mobile" },
				code: { label: "图形验证", prop: "code" },
				authCode: { label: "短信验证", prop: "authCode" }
			},
			registerFlag: false,
			mobileInfo: {}
		}
	},
	computed: { ...mapState(["FVs", "user", "login"]) },
	methods: {
		renderBanner(){
			this.$Xhr({
				url: `https://www.yozuyun.com/api/cont/multi.api`,
				data: {
					channelPath: "merchantRegisterBanner"
				},
				noLoading: true,
				method: "post"
			})
				.then(res => {
					if(res && res.merchantRegisterBanner){
						this.banner = Object.assign(this.banner,res.merchantRegisterBanner)
					}
				})
		},
		syncData(mobileInfo) {
			this.mobileInfo = mobileInfo
		},
		selectCity({ nodes, name }) {
			this.data.ProvinceId = nodes[0].value || ""
			this.data.provinceName = nodes[0].label || ""
			this.data.CityId = (nodes[1] && nodes[1].value) || ""
			this.data.cityName = (nodes[1] && nodes[1].label) || ""
			this.data.householdAddress = name
		},
		onSubmit() {
			if (this.registerFlag) {
				return false
			}
			let { data, mobileInfo } = this
			this.data = Object.assign(data, mobileInfo)
			let { mobile } = this.data
			this.$refs.data
				.validate()
				.then(() => {
					this.$refs.ver.validate().then(() => {
						if (!/^(\d{11})$/.test(mobile)) {
							this.$toast("请输入11位手机号码!", "error")
							return false
						}
						this.registerFlag = true
						this.$Xhr({
							url: `/userLogin/register?code=${this.data.code}`,
							data: this.data,
							method: "post"
						})
							.then(res => {
								this.registerFlag = false
								this.$toast("注册成功")
								this.$store.commit("updateUser", res)
								this.$store.commit("updateLoginStatus", true)
								setTimeout(() => {
									this.go({ name: "index" })
								}, 600)
							})
							.catch(() => {
								this.registerFlag = false
							})
					})
				})
				.catch(() => {
					this.$refs.ver.validate()
				})
		},
		reset() {
			this.$refs.data.resetFields()
		}
	},
	created() {},
	mounted() {
		this.renderBanner()
	},
	components: {
		CitySelect,
		Verify
	}
}
</script>
<style lang="scss" scoped>
.data-container {
	@media screen and (max-width: 1024px) {
		padding: 2.5rem 0 0 0;
	}
}
.data-container .el-form-item {
	@media screen and (max-width: 1024px) {
		margin-bottom: 1.6rem;
	}
}
.city-select {
	width: 100%;
}
.mg-r66 {
	margin-right: 6.6rem;
}
.random-img {
	width: 14rem;
	height: 4rem;
	margin-left: 1rem;
	@media screen and (max-width: 1024px) {
		width: 9.2rem;
		height: 3rem;
	}
}
.msg-btn {
	margin-left: 1rem;
	width: 14rem;
	@media screen and (max-width: 1024px) {
		padding: 1.2rem 1rem;
	}
}
.mobile-btn {
	@media screen and (max-width: 1024px) {
		width: 13rem;
	}
}
.mobile-top {
	@media screen and (max-width: 1024px) {
		margin-top: 2rem;
	}
}
.banner-container{
	width: 100%;
	height: 38rem;
	overflow: hidden;
	position: relative;
	@media screen and (max-width: 1024px) {
		height: 16rem;
	}
}
.banner-container img{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	object-fit: cover;
}
.banner-text{
	display: flex;
    flex-direction: column;
    color: #fff;
    position: relative;
    z-index: 1;
    width: 114rem;
    min-width: 60rem;
    padding: 0 2.7rem;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: center;
    height: calc(100% - 6.5rem);
	@media screen and (max-width: 1024px) {
		height: 100%;
		align-items: center;
		width: 100vw;
		min-width: auto;
		padding: 0;
	}
}
.banner-title{
	font-size: 4.2rem;
	font-weight: bold;
	line-height: 1;
	@media screen and (max-width: 1024px) {
		font-size: 2.1rem;
	}
}
.banner-sec-title{
	font-size: 2rem;
	line-height: 1;
	margin-top: 2.3rem;
	@media screen and (max-width: 1024px) {
		font-size: 1.2rem;
		margin-top: 1.8rem;
	}
}
.register-cont{
	margin-top: -6.5rem;
    border-radius: 1.6rem;
    @media screen and (max-width: 1024px) {
    	margin-top: 0;
		border-radius: 0;
		height: auto;
	}
}

.register-tips{
	position: absolute;
    right: 0;
    top: 15%;
	@media screen and (max-width: 1024px) {
		font-size: 1.2rem;
	}
}
.form-header{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1.6rem 0 4rem;
	position: relative;
	@media screen and (max-width: 1024px) {
    	padding: 0 3rem;
    	margin: 1.6rem 0 1rem;
	}
}
.form-header-title{
	font-size: 2.8rem;
	line-height: 3.8rem;
	@media screen and (max-width: 1024px) {
    	font-size: 1.8rem;
	}
}
.form-header-desc{
	text-align: center;
	font-size: 1.6rem;
	color: #999999;
	line-height: 2.4rem;
	margin-top: 1.6rem;
	@media screen and (max-width: 1024px) {
    	font-size: 1.2rem;
    	margin-top: 0;
    	line-height: 1.8rem;
	}
}
.form-foot-buttons{
	display: flex;
	justify-content: center;
}
</style>
